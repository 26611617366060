<template>
  <div class="d-flex flex-column flex-fill">
    <div class="be-row">
      <div class="col-lg-6 pl-0">
        <div class="be-card mb-4">
          <div>
            <header-with-divider
              :text="$t('dashboard.balance')"
              color="green"
            />
          </div>
          <div class="label-and-button">
            <div class="d-flex f-w-600">
              <span class="price my-4 mr-2 font-weight-bold be-blackColor">
                {{ userBalance | numFormat }}
              </span>
              <span class="mt-4 font-weight-normal be-blackColor">
                {{ currency }}
              </span>
            </div>
            <button
              class="be-btn secondary lg green"
              @click="$router.push({ name: 'withdraw' })"
            >
              {{ $t("dashboard.withdraw") }}
            </button>
          </div>
        </div>
      </div>
      <div class="col-lg-6 pr-0">
        <div class="be-card mb-4">
          <div>
            <header-with-divider
              :text="$t('dashboard.complaints')"
              color="orange"
            />
          </div>
          <div class="label-and-button">
            <div class="d-flex">
              <span class="price my-4 mr-2 font-weight-bold be-blackColor">
                {{ count_ticket }}
              </span>
              <span class="f-w-600 mt-4 font-weight-normal be-blackColor">
                {{ $t("dashboard.tickets") }}
              </span>
            </div>
            <button
              class="be-btn secondary lg orange"
              @click="$router.push({ name: 'assistance' })"
            >
              {{ $t("dashboard.open_ticket") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="be-row flex-fill">
      <div class="col p-0">
        <MovementList :is-dashboard="true" />
      </div>
    </div>
  </div>
</template>

<script>
import MovementList from "./profile/MovementList";
import { mapGetters } from "vuex";
import HeaderWithDivider from "../components/common/HeaderWithDivider";

export default {
  name: "Dashboard",
  components: { MovementList, HeaderWithDivider },
  data() {
    return {
      selected: null,
    };
  },

  computed: {
    ...mapGetters("auth", ["userBalance", "currency"]),
    ...mapGetters("assistance", ["count_ticket"]),
    ...mapGetters("shopping", ["service_types"]),
    operations() {
      let ops = [
        {
          label: this.$t("dashboard.operations.deposit"),
          route: { name: "deposit" },
        },
        {
          label: this.$t("dashboard.operations.withdraw"),
          route: { name: "withdraw" },
        },
      ];
      if (this.service_types) {
        this.service_types.forEach(st =>
          ops.push({
            label: st.web_menu,
            route: {
              name: "shopping.form",
              params: { type_code: st.code.toLowerCase() },
            },
          })
        );
      }
      return ops;
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    openOperationPage(event) {
      const operation = this.operations[event.target.value];
      this.$router.push(operation.route);
    },
  },
};
</script>

<style lang="scss" scoped>
.be-card {
  background: white;
  border-radius: 8px;
  box-shadow: 1px 3px 20px #00000014;
}

.price {
  font-size: 36px;
}

.price ~ span {
  font-size: 25px;
  line-height: 30px;
}

.be-blackColor {
  color: #0b1527;
}

@media screen and (max-width: 992px) {
  .be-row {
    div {
      padding: 0;
    }
  }
}

.be-opened-sidebar ~ .be-main {
  .label-and-button {
    @media (max-width: 1200px) {
      flex-direction: column;
      margin-bottom: 24px;
    }
  }
}
</style>
