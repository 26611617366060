import axios from "axios";

export default {
  getTransferList(page, keyword) {
    return axios.get(
      `/v1/transfers?page=${page}&paginate=10${
        keyword ? "&filter[search]=" + keyword : ""
      }`
    );
  },
  findReceiver(email) {
    return axios.get(`v1/transfers/receivers/${email}`);
  },
  getTransferSettings() {
    return axios.get(`/v1/transfer-settings`);
  },
  getTransfer(id) {
    return axios.get(`/v1/transfers/${id}`);
  },
  confirmTransfer(transferField) {
    return axios.post("/v1/transfers/internals", transferField);
  },
};
