export const settingCode = {
  smsFee: "sms_fee",
  canDeposit: "can_deposit",
};

export const movementTypeCode = {
  credit: "credit",
  debit: "debit",
};

export const statusCode = {
  initiated: "initiated",
  pending: "pending",
  success: "success",
  failed: "failed",
};

export const settingScopeCode = {
  user: "user",
  operator: "operator",
  application: "application",
};

export function isPending(status) {
  return (
    status.toLowerCase() === statusCode.pending ||
    status.toLowerCase() === statusCode.initiated
  );
}
export function isFailed(status) {
  return status.toLowerCase() === statusCode.failed;
}
export function isSuccess(status) {
  return status.toLowerCase() === statusCode.success;
}

export const propertyCode = {
  phone: "phone",
  gender: "gender",
  email: "email",
  otp: "otp",
  first_name: "first_name",
  last_name: "last_name",
  name: "name",
};

export const operatorCode = {
  bewallet: "1",
  orangeMoneyBFA: "2261",
  OMCIV: "2252",
  moovMoneyCIV: "2253",
  waveCIV: "2255",
};

export const typeServiceCode = {
  voyage: "VOY",
  recharge: "REC",
  invoice: "FAC",
  subscription: "ABN",
  sell_card: "VCV",
  charge_card: "CDP",
};

export const serviceIndex = {
  camwatter: "90007",
  canal: "90006",
  eneo: "1001",
  cie: "CIECIV",
  sodeci: "SODECICIV",
  visa: "VISA",
  buy_uba: "BUY_VISA",
};

export const serverApiCode = {
  smobilpay: "APICMRS01",
  lohce: "APICMRS02",
  cinetpay: "APIEXT03",
  abyster: "APIEXT04",
  futhermarket: "APICMR05",
  omcmr: "APICMR001",
  omcmr_new: "APICMR003",
  momocmr: "APICMR002",
  intouch: "APICMRS03",
  intouch_civ_mp: "APICIVS01",
  intouch_bfa: "APICMRS04",
  intouch_gnq: "APIGNQS01",
  intouch_sen: "APISENS01",
  intouch_mli: "APIMLIS01",
  manuel: "NOTAPI000",
  ligdicash: "APIBFA001",
  ligdicash_redirect: "APIBFA002",
  viewpay: "APIL2V001",
  neosurf: "APIEXT05",
  makabine: "APIEXT06",
  paiementpro: "APIEXT07",
  visa_redirect: "REDIRECT",
  momociv: "APICIV001",
  smshub: "APISEN001",
  omsen: "APISEN002",
  bitpay: "APIBCT001",
  blockchain: "APIBCT002",
  paydunya: "APIEXT005",
  paydunya_redirect: "APIEXT006",
};

export const fieldLength = {
  sodeci_ref: 9,
  cie_id: 9,
  camwatter_min: 8,
  camwatter_max: 14,
};

export const typeTicketCode = {
  ...typeServiceCode,
  payment: "payment",
  withdraw: "withdraw",
  deposit: "deposit",
  number: "numbers",
};

export const emptyListModel = {
  message: "message",
  shopping: "shopping",
  movement: "movement",
  deposit: "deposit",
  withdraw: "withdraw",
  disableWithdraw: "disableWithdraw",
  disableDeposit: "disableDeposit",
  transfer: "transfer",
  assistance: "assistance",
  payment: "payment",
  merchantPayment: "merchantPayment",
};

export const depositResponseState = {
  success: "success",
  cancel: "cancel",
};

export const operationStatus = {
  success: "Succès",
  failed: "Echec",
  pending: "En cours",
};
