import { mapGetters } from "vuex";
import { getUserSession, setUserSession } from "@/helpers/utils";

export const authComputed = {
  ...mapGetters("auth", ["loggedIn"]),
};

export function editCurrentUser(user) {
  let userData = getUserSession("current_user_credentials");
  userData.user = user;
  setUserSession("current_user_credentials", userData);
}
