import DepositService from "@/services/DepositService";
import OperatorService from "@/services/OperatorService";
import { isPending, isSuccess, settingScopeCode } from "@/helpers/constants";
import { returnBoolValue } from "@/helpers/helpers";
const state = {
  metaDeposit: null,
  deposit_operators: [],
  user_settings: [],
};

const getters = {
  deposits: state => (state.metaDeposit ? state.metaDeposit.data : []),
  current_page: state =>
    state.metaDeposit ? state.metaDeposit.meta.current_page : 1,
  last_page: state =>
    state.metaDeposit ? state.metaDeposit.meta.last_page : 1,
  operators: state =>
    state.deposit_operators.map(dop => {
      return {
        id: dop.id,
        code: dop.code,
        logo_url: dop.logo_url,
        label: dop.label,
      };
    }),
  getOperatorById: state => id => {
    const operator = state.deposit_operators.find(op => op.id === id);
    if (operator !== undefined)
      return {
        ...operator,
        setting: Object.fromEntries(
          operator.settings.map(obj => [obj.code, obj.value])
        ),
      };
    else return {};
  },
  userConfig: state =>
    Object.fromEntries(state.user_settings.map(obj => [obj.code, obj.value])),
  canProcessDeposit: (state, getters) =>
    returnBoolValue(getters.userConfig?.can_deposit ?? false),
  isDepositAvailable: (state, getters) =>
    state.deposit_operators.length > 0 && getters.canProcessDeposit,
};

const actions = {
  getDepositsList({ commit, state, getters }, { page, keyword }) {
    if (!keyword && state.metaDeposit && page === getters.current_page)
      return state.metaDeposit;
    else
      return DepositService.getDepositList(page, keyword).then(({ data }) => {
        commit("SET_META_DEPOSIT", data);
        return data;
      });
  },
  getDeposit({ getters, dispatch }, id) {
    const deposit = getters.deposits.find(w => w.id === id);
    if (deposit !== undefined) return deposit;
    else
      return DepositService.getDeposit(id)
        .then(({ data }) => {
          return data.data;
        })
        .catch(err => {
          if (err.response.data.message) {
            const notification = {
              type: "danger",
              message: err.response.data.message,
              is_toast: true,
            };
            dispatch("notification/add", notification, { root: true });
          }
          return Promise.reject(err.response.data);
        });
  },
  getDepositOperators({ commit, state }) {
    if (state.deposit_operators.length > 0) return state.deposit_operators;
    else
      return OperatorService.getDepositOperators().then(({ data }) => {
        commit("SET_DEPOSIT_OPERATORS", data.data);
      });
  },
  getUserSettings({ commit, state, rootGetters }) {
    if (state.user_settings.length) return state.user_settings;
    else
      return DepositService.getDepositSettings(
        settingScopeCode.user,
        rootGetters["auth/userId"]
      ).then(({ data }) => {
        commit("SET_USER_SETTINGS", data.data);
        return data.data;
      });
  },
  initiateDeposit({ commit, dispatch, state }, depositField) {
    return DepositService.initiateDeposit({
      ...depositField,
      svc_number: depositField.phone,
    })
      .then(({ data }) => {
        if (data.message) {
          const notification = {
            type: "success",
            message: data.message,
            is_toast: false,
          };
          dispatch("notification/add", notification, { root: true });
        }
        if (data.deposit && state.metaDeposit)
          commit("ADD_DEPOSIT", data.deposit);

        return data;
      })
      .catch(err => {
        if (err.response.data.message) {
          const notification = {
            type: "danger",
            message: err.response.data.message,
            is_toast: false,
          };
          dispatch("notification/add", notification, { root: true });
        }
        return Promise.reject(err.response);
      });
  },
  verifyDeposit({ commit, dispatch }, deposit) {
    return DepositService.verifyDeposit(deposit.id)
      .then(({ data }) => {
        if (deposit.status !== data.status) {
          commit("UPDATE_DEPOSIT", { ...deposit, status: data.status });

          if (!isPending(data.status)) {
            if (isSuccess(data.status)) {
              commit(
                "auth/TEMP_ADD_TO_BALANCE",
                parseFloat(deposit.base_amount),
                {
                  root: true,
                }
              );
            }
            const notification = {
              type: isSuccess(data.status) ? "success" : "danger",
              message: data.message,
              is_toast: true,
            };
            dispatch("notification/add", notification, { root: true });
          }
        }
        return data;
      })
      .catch(err => {
        if (err.response.data.message) {
          const notification = {
            type: "danger",
            message: err.response.data.message,
            is_toast: false,
          };
          dispatch("notification/add", notification, { root: true });
        }
        return Promise.reject(err.response);
      });
  },

  createOperatorNumber({ commit, dispatch, state }, numberField) {
    return OperatorService.addOperatorNumber(numberField).then(({ data }) => {
      if (data.data.is_confirm) {
        commit("ADD_OPERATOR_NUMBER", data.data);

        const notification = {
          type: "success",
          message: state.$i18n.t("deposit.number_is_confirm"),
          is_toast: true,
        };
        dispatch("notification/add", notification, { root: true });
      }

      return data;
    });
  },

  verifyOperatorNumber({ commit, dispatch }, { number_id, otp }) {
    return OperatorService.verifyOperatorNumber(number_id, { otp }).then(
      ({ data }) => {
        commit("ADD_OPERATOR_NUMBER", data.number);

        const notification = {
          type: "success",
          message: data.message,
          is_toast: true,
        };
        dispatch("notification/add", notification, { root: true });

        return data;
      }
    );
  },

  resendOperatorNumberOtpCode({ dispatch }, otpId) {
    return OperatorService.resendOperatorNumberOtpCode(otpId).then(
      ({ data }) => {
        const notification = {
          type: "success",
          message: data.message,
          is_toast: true,
        };
        dispatch("notification/add", notification, { root: true });

        return data;
      }
    );
  },
};

const mutations = {
  SET_META_DEPOSIT(state, metaDeposit) {
    state.metaDeposit = metaDeposit;
  },
  SET_DEPOSIT_OPERATORS(state, deposit_operators) {
    state.deposit_operators = deposit_operators;
  },
  SET_USER_SETTINGS(state, user_settings) {
    state.user_settings = user_settings;
  },
  ADD_DEPOSIT(state, deposit) {
    if (state.metaDeposit) state.metaDeposit.data.unshift(deposit);
  },
  UPDATE_DEPOSIT(state, deposit) {
    state.metaDeposit.data = state.metaDeposit.data.map(d => {
      if (d.id === deposit.id) return deposit;
      else return d;
    });
  },

  ADD_OPERATOR_NUMBER(state, number) {
    state.deposit_operators = state.deposit_operators.map(function(op) {
      if (op.id === number.operator_id) op.numbers.push(number);
      return op;
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
