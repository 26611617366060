import Echo from "laravel-echo";
import Pusher from "pusher-js";
window.Pusher = Pusher;
import { getUserSession } from "@/helpers/utils";

const url =
  process.env.NODE_ENV !== "production" ? "127.0.0.1" : "staging.be-wallet.net";
const authBaseUrl =
  process.env.NODE_ENV !== "production"
    ? `http://${url}:3000`
    : `https://${url}`;

let echo_instance = new Echo({
  broadcaster: "reverb",
  key: process.env.REVERB_APP_KEY ?? "my-app-key",
  wsHost: process.env.REVERB_HOST ?? url,
  wsPort: process.env.REVERB_PORT ?? 6001,
  wssPort: process.env.REVERB_PORT ?? 6001,
  forceTLS: (process.env.REVERB_SCHEME ?? "http") === "https",
  enabledTransports: ["ws", "wss"],
  authEndpoint: `${authBaseUrl}/api/broadcasting/auth`,
  auth: {
    headers: {
      accept: "application/json",
    },
  },
});

const userData = getUserSession("current_user_credentials");
if (userData)
  echo_instance.connector.options.auth.headers[
    "Authorization"
  ] = `Bearer ${userData.token}`;

export default echo_instance;
