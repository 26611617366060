import axios from "axios";

export default {
  getWithdrawList(page, keyword) {
    return axios.get(
      `/v1/withdraws?page=${page}&paginate=10${
        keyword ? "&filter[search]=" + keyword : ""
      }`
    );
  },
  getWithdraw(id) {
    return axios.get(`/v1/withdraws/${id}`);
  },
  getWithdrawSettings(scoped = "", scope_id = "") {
    return axios.get(
      `/v1/withdraw-settings${
        scoped && scope_id ? `?scope=${scoped}&scope_id=${scope_id}` : ""
      }`
    );
  },

  confirmWithdraw(payload) {
    return axios.post("/v1/withdraws", payload);
  },

  getContacts() {
    return axios.get("/v1/contacts?include=service_numbers");
  },
  addContact(contact) {
    return axios.post("/v1/contacts", contact);
  },
  updateContact(contact) {
    return axios.put(`/v1/contacts/${contact.id}`, contact);
  },
  deleteContact(id) {
    return axios.delete(`/v1/contacts/${id}`);
  },

  addServiceNumber(serviceNumber) {
    return axios.post("/v1/service-numbers", serviceNumber);
  },
  deleteServiceNumber(id) {
    return axios.delete(`/v1/service-numbers/${id}`);
  },
};
