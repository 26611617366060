import { movementTypeCode, statusCode } from "@/helpers/constants";

export const OperationClassMixin = {
  methods: {
    operationIcon(type) {
      switch (type?.toLowerCase()) {
        case movementTypeCode.credit:
          return "icon-deposit-fill";
        case movementTypeCode.debit:
          return "icon-withdraw-fill";
      }
    },
    operationClass(type) {
      switch (type?.toLowerCase()) {
        case movementTypeCode.credit:
          return "be-text-success";
        case movementTypeCode.debit:
          return "be-text-failure";
      }
    },
    activeClass(status) {
      switch (status?.toLowerCase()) {
        case statusCode.success:
          return "be-text-success";
        case statusCode.failed:
          return "be-text-failure";
        case statusCode.pending:
        default:
          return "be-text-pending";
      }
    },
    statusClass(status) {
      switch (status?.toLowerCase()) {
        case statusCode.success:
          return "badge-success";
        case statusCode.failed:
          return "badge-failure";
        case statusCode.pending:
        default:
          return "badge-info";
      }
    },
    statusClassIcon(status) {
      switch (status?.toLowerCase()) {
        case statusCode.success:
          return "fas fa-thumbs-up text-success";
        case statusCode.failed:
          return "fas fa-thumbs-down text-danger";
        case statusCode.pending:
        default:
          return "fas fa-hourglass-half text-info";
      }
    },
    statusColorClass(status) {
      switch (status) {
        case statusCode.success:
          return "be-text-success";
        case statusCode.failed:
          return "be-text-failure";
        case statusCode.pending:
        default:
          return "be-text-pending";
      }
    },
  },
};
