<template>
  <div class="be-date-picker">
    <span class="be-date-picker-label f-w-600" v-if="label">{{ label }}</span>
    <date-picker
      v-on="listeners"
      style="width: 100%"
      :input-class="'form-control' + (isInvalid ? ' is-invalid' : '')"
      :value="new Date(value)"
      :default-value="customDataValue"
      v-bind="$attrs"
      :format="format"
      :lang="$moment.locale()"
      :disabled-date="disableDate"
    >
    </date-picker>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/en";
import "vue2-datepicker/locale/fr";

export default {
  name: "BaseDatepicker",
  inheritAttrs: false,
  components: { DatePicker },
  props: {
    value: [String, Date],
    format: {
      type: String,
      default: "DD-MM-YYYY",
    },
    notAfter: {
      type: [Date, String],
      default: null,
    },
    notBefore: {
      type: [Date, String],
      default: null,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue,
      };
    },
    customDataValue() {
      return this.notAfter
        ? this.notAfter
        : this.notBefore
        ? this.notBefore
        : null;
    },
  },
  methods: {
    disableDate(date) {
      let result = false;
      if (this.notBefore) result = date < new Date(this.notBefore);
      if (this.notAfter) result = date > new Date(this.notAfter);
      return result;
    },
    updateValue(event) {
      this.$emit("input", event);
    },
  },
};
</script>

<style scoped></style>
