<template>
  <div class="be-card h-100">
    <div class="position-relative">
      <header-with-divider
        :text="$t('profile.operations_history')"
        color="blue"
      />
      <button
        v-if="isDashboard"
        class="be-btn secondary lg blue d-none d-md-block all-operations"
        @click="$router.push({ name: 'movement' })"
      >
        {{ $t("profile.all_operations") }}
      </button>
      <div v-else>
        <div
          id="buttons-container"
          class="d-flex align-items-center justify-content-between p-4"
        >
          <BaseInputSearch @search="search" />
          <button
            @click.prevent="printMovement"
            class="be-btn primary lg blue i-right f-w-600"
          >
            Export PDF<i class="icon-deposit-fill" />
          </button>
        </div>
        <div class="divider" />
      </div>
    </div>
    <loading v-if="loading" />
    <div
      v-else-if="!loading && movements && movements.length"
      class="be-table-wrapper scroll-wrapper"
    >
      <be-table
        :data="searchedValue ? filteredMovements : movements"
        :fields="smallSize ? smallTableFields : fields"
        :hoverOnRow="false"
        :smallSize="smallSize"
        id="movement-table"
      >
        <template #type="{ row }">
          <div class="d-flex align-items-center">
            <i
              :class="[operationIcon(row.type), operationClass(row.type)]"
              class="op-icon"
            />
            <span>
              {{ $t(`status.type.${row.type.toLowerCase()}`) }}
            </span>
          </div>
        </template>
        <template #desc="{ row }">
          <label class="f-14" v-html="row.desc" />
          <small class="d-block">
            <div v-if="!smallSize">
              <span class="f-w-600"> Ref - {{ row.reference }}</span>
            </div>
          </small>
        </template>
        <template #amount="{ row }">
          <div class="d-flex flex-column">
            {{ row.amount + " " + row.currency }}
            <small
              v-if="!smallSize"
              :class="activeClass(row.status)"
              class="f-w-600"
            >
              {{ $t(`status.${row.status.toLowerCase()}`) }}
            </small>
          </div>
        </template>
        <template #status="{ row }">
          <div>
            <span :class="activeClass(row.status)" class="f-w-600">
              {{ $t(`status.${row.status.toLowerCase()}`) }}
            </span>
          </div>
        </template>
        <template #solde="{ row }">
          {{ row.balance + " " + row.currency }}
        </template>
        <template #created_at="{ row }">
          <div v-if="smallSize">
            {{ row.created_at | moment("Do MMMM YYYY H:mm:ss") }}
          </div>
          <div v-else class="d-flex flex-column">
            <span>{{ row.created_at | moment("Do MMMM YYYY") }}</span>
            <small>{{ row.created_at | moment("H:mm:ss") }}</small>
          </div>
        </template>
      </be-table>
    </div>
    <no-content
      v-else
      :text="$t('profile.no_operations_at_moment')"
      icon="icon-movements"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import BeTable from "@/components/BeTable";
import HeaderWithDivider from "@/components/common/HeaderWithDivider";
import Loading from "@/components/common/Loading";
import NoContent from "@/components/common/NoContent";
import { OperationClassMixin } from "@/mixins/OperationClassMixin";
import printJS from "print-js";

export default {
  name: "MovementList",
  mixins: [OperationClassMixin],
  components: {
    BeTable,
    HeaderWithDivider,
    Loading,
    NoContent,
  },
  props: {
    isDashboard: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: true,
      size: null,
      searchedValue: null,
    };
  },

  computed: {
    ...mapState({
      sidebarState: state => state.sidebarState,
    }),
    ...mapGetters("auth", ["getMovementList"]),
    fields() {
      return [
        {
          label: this.$t("common.operation"),
          key: "type",
        },
        {
          label: this.$t("common.description"),
          key: "desc",
          class: "col-3",
        },
        {
          label: this.$t("common.amount"),
          key: "amount",
        },
        {
          label: this.$t("common.balance"),
          key: "solde",
        },
        {
          label: this.$t("common.date"),
          key: "created_at",
        },
      ];
    },
    smallTableFields() {
      return [
        {
          label: this.$t("common.operation"),
          key: "type",
        },
        {
          label: this.$t("common.date"),
          key: "created_at",
        },
        {
          label: this.$t("common.ref"),
          key: "reference",
          class: "break-all",
        },
        {
          label: this.$t("common.amount"),
          key: "amount",
        },
        {
          label: this.$t("common.balance"),
          key: "solde",
        },
        {
          label: this.$t("common.status"),
          key: "status",
        },
        {
          label: this.$t("common.description"),
          key: "desc",
        },
      ];
    },
    title() {
      if (this.isDashboard) {
        return this.movements.length > 1
          ? this.$t("recent_activities")
          : this.$t("profile.recent_activity");
      } else return this.$t("profile.movement_historic_in_bewallet");
    },
    smallSize() {
      return this.$screen.width - (this.sidebarState ? 320 : 100) < 992;
    },
    movements() {
      return this.isDashboard
        ? this.getMovementList(6)
        : this.getMovementList();
    },
    filteredMovements() {
      return this.movements.filter(m => {
        return Object.values(m).filter(objVal => {
          return objVal
            ?.toString()
            .toLowerCase()
            .includes(this.searchedValue?.toLowerCase());
        }).length;
      });
    },
  },

  created() {
    this.reloadMovementList();
  },
  methods: {
    reloadMovementList(page = 1) {
      this.loading = true;
      this.$store
        .dispatch("auth/getMovements", { page })
        .then(() => {
          this.loading = false;
        })
        .catch(err => {
          console.log(err);
          this.loading = false;
        });
    },
    search(val) {
      this.searchedValue = val;
    },

    printMovement() {
      printJS("movement-table", "html");
    },
  },
};
</script>

<style lang="scss" scoped>
.page-item.active .page-link {
  background-color: #32849c !important;
}

.op-icon {
  font-size: 24px;
  margin-right: 6px;
}

.all-operations {
  position: absolute;
  top: 15px;
  right: 24px;
}

// .be-opened-sidebar ~ .be-main {
//   .all-operations-container {
//     @media (max-width: 850px) {
//       flex-direction: column;
//       .all-operations {
//         margin-top: 10px;
//         margin-right: 0;
//       }
//     }
//   }
// }
</style>
