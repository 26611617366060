<template>
  <div>
    <!-- [ navigation menu ] start -->
    <!-- [ navigation menu ] end -->

    <!-- [ Header ] start -->
    <!-- <Header /> -->
    <!-- [ Header ] end -->
    <div class="be-row">
      <Header
        :isSidebarOpened="isSidebarOpened"
        @toggle-sidebar="toggleSidebar"
      />
    </div>
    <div class="be-row">
      <vertical-menu
        :isSidebarOpened="isSidebarOpened"
        @toggle-sidebar="toggleSidebar"
      />
      <div class="be-main" :class="{ 'be-main-s-closed': !isSidebarOpened }">
        <TicketList ref="tickerList" />

        <!-- [ chat user list ] start -->
        <!-- [ chat user list ] end -->

        <!-- [ Main Content ] start -->

        <div class="pcoded-main-container bacground-color mb-4">
          <div class="pcoded-wrapper h-100">
            <GlobalAlert />
            <div class="pcoded-content h-100">
              <transition name="slide-fade" mode="out-in">
                <router-view />
              </transition>
              <!-- <div class="pcoded-inner-content h-100"> -->
              <!-- <BreadCrumb class="d-none d-sm-block" :prev-route="prevRoute" /> -->
              <!-- <div class="main-body h-100"> -->
              <!-- <div class="page-wrapper h-100"> -->
              <notification-container />
              <!-- </div> -->
              <!-- </div> -->
              <!-- </div> -->
            </div>
            <div class="fab-button d-none d-lg-block">
              <button
                class="be-btn primary blue circle-btn lg"
                @click="setTicketsState(!ticketsState)"
              >
                <i
                  class="fas fa-headphones text-white f-26 mr-0"
                  v-if="!loadingHelp"
                ></i>
                <BaseLoader
                  v-if="loadingHelp"
                  :is-small="true"
                  text-color="text-white"
                />
              </button>
            </div>
          </div>
          <Footer />
        </div>
        <!-- [ Main Content ] end -->
      </div>
    </div>
    <BeBottomNav />
  </div>
</template>

<script>
import VerticalMenu from "./VerticalMenu";
import Header from "./Header";
import TicketList from "../assistance/TicketList";
import Footer from "./Footer";
// import BreadCrumb from './BreadCrumb';
import NotificationContainer from "../notification/NotificationContainer";
// import Vue from 'vue';
import { assistanceMixin } from "@/mixins/assistanceMixiin";
import GlobalAlert from "./GlobalAlert";
import { mapActions, mapGetters, mapState } from "vuex";
import BeBottomNav from "./BeBottomNav";
import echo from "@/config/echo";
import { getUserSession } from "@/helpers/utils";

export default {
  name: "Account",

  components: {
    GlobalAlert,
    NotificationContainer,
    // BreadCrumb,
    Footer,
    TicketList,
    Header,
    VerticalMenu,
    BeBottomNav,
  },

  mixins: [assistanceMixin],

  data() {
    return {
      prevRoute: null,
      timeout: null,
      isSidebarOpened: false,
    };
  },

  // watch: {
  //   $route(current, prev) {
  //     this.closeAssistance();
  //     if (prev) {
  //       if (current.name !== prev.name) {
  //         const baseName = prev.name.split('.');
  //         if (RegExp(baseName[0] + '*').test(this.$route.name))
  //           this.prevRoute = prev;
  //         else this.prevRoute = null;
  //       } else this.prevRoute = null;
  //     } else this.prevRoute = null;
  //   },
  // },

  metaInfo() {
    return {
      title: this.$t(`menu.${this.$route.name}.title`),
    };
  },

  created() {
    this.$store.dispatch("auth/getCurrentUser");
    this.$store.dispatch("auth/getNotifications", 1);
    // this.$store.dispatch("shopping/getServiceList", {
    //   page: 1,
    //   field: { countries_id: this.country_id, is_active: true },
    // });
    this.prevRoute = null;
    this.isSidebarOpened = this.$screen.width >= 991;
  },

  computed: {
    ...mapState(["ticketsState"]),
    ...mapGetters("auth", ["userId"]),
  },

  mounted() {
    this.initiateEchoSocket();
  },

  methods: {
    ...mapActions(["setSidebarState", "setTicketsState"]),
    initiateEchoSocket() {
      if (this.userId) {
        const userData = getUserSession("current_user_credentials");
        if (userData)
          echo.connector.options.auth.headers[
            "Authorization"
          ] = `Bearer ${userData.token}`;
        echo
          .private(`notifications.${this.userId}`)
          .notification(function(notify) {
            console.log(notify);
          })
          .error(error => {
            error.on("reconnect_error", () => {
              this.$store.dispatch("changeEchoConnexionState", false);
              echo.disconnect();
            });
          });
      }
    },
    toggleSidebar() {
      this.isSidebarOpened = !this.isSidebarOpened;
      this.setSidebarState(this.isSidebarOpened);
    },
  },
};
</script>

<style lang="scss" scoped></style>
