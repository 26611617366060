import Vue from "vue";
import VueRouter from "vue-router";
import account from "./account";
import Account from "../components/layouts/Account";
import NProgress from "nprogress";
import store from "@/store";
import authMiddleware from "../middleware/authMiddleware";
import Landing from "../views/Landing";
import { blockchainMonitorCallback } from "@/middleware/callbackMiddleware";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Landing,
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/auth/Login"),
    meta: { redirectAuth: true },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/auth/Register"),
    meta: { redirectAuth: true },
  },
  {
    path: "/password-forget",
    name: "PasswordReset",
    component: () => import("../views/auth/PasswordReset"),
    meta: { redirectAuth: true },
  },
  {
    path: "/password-reset/:token",
    name: "PasswordChange",
    component: () => import("../views/auth/PasswordResetConfirm"),
    meta: { redirectAuth: true },
  },
  {
    path: "/email-verify",
    name: "EmailVerify",
    component: () => import("../views/auth/EmailVerify"),
    meta: { requiresAuth: true },
  },
  {
    path: "/account",
    component: Account,
    children: account,
    meta: { requiresAuth: true, requiresEmailVerification: true },
  },
  {
    path: "/maintenance",
    name: "maintenance",
    component: () => import("../views/PageMaintenance"),
  },
  {
    path: "/authorizations/:signature",
    name: "authorization",
    component: () => import("../views/BotAccessAuthorize"),
    meta: { requiresAuth: true},
  },
  {
    path: "/404",
    name: "pageNotFound",
    component: () => import("../views/PageNotFound"),
  },
  {
    path: "/blockchain-monitor/invoice-callback-notify",
    beforeEnter: blockchainMonitorCallback,
  },
  {
    path: "**",
    redirect: { name: "pageNotFound" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(authMiddleware);

router.afterEach(() => {
  NProgress.done();
  store.dispatch("stopRouterLoading");
});

export default router;
