var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-group"},[(!!_vm.label)?_c('div',{staticClass:"input-label f-w-600",class:{ 'input-strong': _vm.bold, 'input-danger': _vm.errorMessage }},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('div',{staticClass:"input-group position-relative",class:{ leftIconInactive: !_vm.value }},[(_vm.leftIconClass)?_c('i',{class:[_vm.leftIconClass, 'input-icon']}):_vm._e(),_c('input',_vm._g(_vm._b({class:{
        'is-invalid': _vm.isInvalid,
        wInput: true,
        hasLeftIcon: !!_vm.leftIconClass,
        'pr-5': true,
      },attrs:{"type":_vm.isVisible ? 'text' : 'password'},domProps:{"value":_vm.value},on:{"input":_vm.updateValue}},'input',_vm.$attrs,false),_vm.listeners)),_c('i',{staticClass:"visible-image",class:{
        'icon-eye': !_vm.isVisible,
        'icon-eye-off': _vm.isVisible,
      },on:{"click":function($event){$event.preventDefault();return _vm.setVisible.apply(null, arguments)}}})]),(_vm.errorMessage)?_c('div',{staticClass:"input-error-message"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }