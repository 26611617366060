import axios from "axios";

export default {
  getCountries() {
    return axios.get(`/v1/countries?filter[is_active]=1`);
  },
  checkEndOfMaintenanceMode() {
    return axios.get("/check-end-of-maintenance");
  },
};
