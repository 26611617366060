<template>
  <div :class="apiSearch ? 'be-api-search' : 'be-input-search'">
    <template v-if="apiSearch">
      <input
        :disabled="disabled"
        :class="{
          disabled: disabled,
          'w-100': true,
        }"
        type="text"
        v-model="keyword"
        @keydown.enter="$emit('search', keyword)"
        :placeholder="$t('common.find')"
      />
      <a
        href="#"
        class="icon-for-input"
        :title="$t('common.search')"
        @click.prevent="$emit('search', keyword)"
      />
    </template>
    <template v-else>
      <input
        :disabled="disabled"
        :class="{
          disabled: disabled,
          'w-100': true,
        }"
        type="text"
        :value="value"
        @input="onInput"
        :placeholder="$t('common.find')"
      />
      <span class="icon-for-input" />
    </template>
  </div>
</template>

<script>
export default {
  name: "BaseInputSearch",
  props: {
    value: {
      type: [String, Number],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "md",
    },
    apiSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      keyword: null,
    };
  },
  methods: {
    onInput(event) {
      this.$emit("search", event.target.value);
      this.$emit("input", event.target.value);
    },
    cancelSearch() {
      this.$emit("cancel");
    },
  },
  beforeDestroy() {
    if (this.value) this.$emit("cancel");
  },
};
</script>

<style scoped>
.disabled {
  background-color: white;
  border: 1px dashed #e1e4ea;
  border-radius: 25px;
  cursor: not-allowed;
}
</style>
