import axios from "axios";
import store from "@/store";

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axios.defaults.timeout = 120000;
axios.defaults.crossDomain = true;
axios.defaults.baseURL =
  process.env.NODE_ENV !== "production"
    ? "http://127.0.0.1:3000/api"
    : "https://staging.be-wallet.net/api";

axios.interceptors.response.use(
  response => response,
  async error => {
    if (error.response) {
      if (error.response.status === 401) {
        store.dispatch("auth/logout", false).finally(() => location.reload());
      }
      if (error.response.status === 503) {
        const retryAfter = parseInt(
          error.response.headers["retry-after"] ?? "10000"
        );
        if (retryAfter <= 20000) {
          let config = error.config;
          config.headers["retry-count"] = retryAfter / 10000 + 1;
          return new Promise((resolve, reject) => {
            setTimeout(
              () => resolve(axios(error.config).catch(err => reject(err))),
              retryAfter
            );
          });
        }
      }
      return Promise.reject(error);
    } else {
      // store.dispatch("auth/logout", false);
      return Promise.reject(error);
    }
  }
);
