<template>
  <div class="simple-select" @click="fn" v-if="name === 'my_profile'">
    <div
      class="label-rounded profile-select"
      :class="{ 'profile-select-opened': show }"
    >
      <i class="mr-2 f-24" :class="icon" />
      <span :class="{ opened: show }" v-if="label">
        {{ label }}
      </span>
      <MyProfilePopover :profileOptions="options" v-if="show" />
    </div>
  </div>
  <div
    class="simple-select"
    @click="fn"
    v-else-if="name === 'my_notifications'"
  >
    <div
      class="rounded-icon mr-3 note-select"
      :class="{ 'note-select-opened': show }"
    >
      <i class="f-24" :class="icon" />
      <span :class="{ opened: show }" v-if="label">
        {{ label }}
      </span>
      <NotificationPopover v-if="show" />
    </div>
  </div>
</template>

<script>
import NotificationPopover from "./NotificationPopover.vue";
import MyProfilePopover from "./MyProfilePopover.vue";

export default {
  components: { NotificationPopover, MyProfilePopover },
  name: "BaseSelect",
  props: {
    icon: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  created() {
    document.body.addEventListener("click", this.close, false);
  },
  beforeDestroy() {
    document.body.removeEventListener("click", this.close, false);
  },
  methods: {
    fn(e) {
      e.stopPropagation();
      this.show = !this.show;
    },
    close() {
      this.show = false;
    },
    logout() {
      this.$store.dispatch("auth/logout").then(() => {
        location.assign("/login");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 1200px) {
  .profile-select {
    padding: 9px;
    > span {
      display: none;
      &::after {
        content: none;
      }
    }
    > i {
      margin: 0 !important;
    }
  }
}
</style>
