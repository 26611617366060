import store from "@/store";

const profile = [
  {
    path: "/",
    redirect: { name: "formProfile" },
  },
  {
    path: "edit",
    name: "profile",
    component: () => import("../views/profile/FormProfile"),
    beforeEnter(routeTo, routeFrom, next) {
      store.dispatch("getCountries").then(() => {
        next();
      });
    },
  },
  {
    path: "configurations",
    name: "formSetting",
    component: () => import("../views/profile/FormSetting"),
    beforeEnter(routeTo, routeFrom, next) {
      store.dispatch("auth/getCurrentUserSettings", true).then(() => {
        next();
      });
    },
  },
  {
    path: "security-confidential",
    name: "formPassword",
    component: () => import("../views/profile/FormPassword"),
    children: [
      {
        path: "multi-factor-auth",
        name: "formPassword.mfa",
        component: () => import("../views/profile/FormMultiFactorAuth.vue"),
      },
    ],
  },
];

export default profile;
