<template>
  <div>
    <div class="be-footerMenu">
      <div>
        <div class="label-rounded">
          <i class="icon-card-fill f-24" />
          <span> {{ userBalance | numFormat }} {{ currency }} </span>
        </div>

        <div
          class="label-rounded"
          @click="fn('my_notifications', $event)"
          :class="{ 'label-rounded-open': selectedItem === 'my_notifications' }"
        >
          <i class="icon-bell f-24" />
          <span>{{ $t("base.notifications") }}</span>
          <span class="note-count" v-if="totalNotificationCount">
            {{ totalNotificationCount }}
          </span>
        </div>

        <div
          class="label-rounded"
          @click="fn('my_acheters', $event)"
          :class="{ 'label-rounded-open': selectedItem === 'my_acheters' }"
        >
          <i class="icon-plus f-24" />
          <span>{{ $t("base.buy_a_service") }}</span>
        </div>

        <div
          class="label-rounded"
          @click="fn('my_profile', $event)"
          :class="{ 'label-rounded-open': selectedItem === 'my_profile' }"
        >
          <i class="icon-user-fill f-24" />
          <span class="text-capitalize"> {{ username }} </span>
        </div>
      </div>
    </div>
    <BeBottomNavPopover
      v-if="selectedItem"
      :item="selectedItem"
      :closeF="close"
    />
    <div class="overlay-blur" v-if="selectedItem" @click="close" />
  </div>
</template>

<script>
import BeBottomNavPopover from "../common/BeBottomNavPopover";
import { mapGetters } from "vuex";

export default {
  name: "BeBottomNav",
  components: { BeBottomNavPopover },
  data() {
    return {
      selectedItem: "",
    };
  },
  methods: {
    fn(x, e) {
      e.stopPropagation();
      document.body.style.overflow = "hidden";
      if (x === this.selectedItem) {
        this.close();
      } else {
        this.selectedItem = x;
      }
    },
    close() {
      this.selectedItem = "";
      document.body.style.overflow = "auto";
    },
  },

  computed: {
    ...mapGetters("auth", [
      "currency",
      "totalNotificationCount",
      "userBalance",
      "username",
    ]),
  },
};
</script>

<style lang="scss" scoped>
.overlay-blur {
  @media (max-width: 991px) {
    visibility: visible;
  }
  visibility: hidden;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 200;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  opacity: 0.9;
}
</style>
