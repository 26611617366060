import store from "@/store";

const transfer = [
  {
    path: "/",
    redirect: { name: "transfer" },
  },
  {
    path: "list",
    name: "transfer",
    component: () => import("@/views/transfer/TransferList"),
  },
  {
    path: "initiate",
    name: "transfer.form",
    component: () => import("@/views/transfer/TransferForm"),
    beforeEnter(routeTo, routeFrom, next) {
      store.dispatch("transfer/getTransferSettings").then(() => {
        next();
      });
    },
  },
];

export default transfer;
