import axios from "axios";

export default {
  getDepositOperators() {
    return axios.get("/v1/deposit-operators");
  },

  getWithdrawOperators() {
    return axios.get("/v1/withdraw-operators");
  },

  addOperatorNumber(number) {
    return axios.post("/v1/numbers", number);
  },

  verifyOperatorNumber(number_id, otpField) {
    return axios.post(`/v1/numbers/${number_id}/verify`, otpField);
  },

  resendOperatorNumberOtpCode(otp_code_id) {
    return axios.get(`/v1/numbers/verification-sms/${otp_code_id}`);
  },

  getNumbers() {
    return axios.get("/v1/numbers");
  },

  getAllOperators(payload) {
    return axios.post("/v1/operators/list", payload);
  },
};
