import axios from "axios";
import AuthService from "../../services/AuthService";
import { editCurrentUser } from "../helpers";
import { emptyListModel, settingCode } from "@/helpers/constants";
import { getUserSession, isEmpty, setUserSession } from "@/helpers/utils";
import NotificationService from "@/services/NotificationService";

const state = {
  user: null,
  haveToConfirm2Fa: false,
  metaMovements: [],
  metaMerchantPayment: null,
  metaNotification: null,
  settings: [],
};

// getters
const getters = {
  loggedIn: state => {
    return !!state.user;
  },
  isCompleteProfile: state => {
    return (
      !isEmpty(state.user.phone) &&
      !isEmpty(state.user.last_name) &&
      !isEmpty(state.user.first_name) &&
      !isEmpty(state.user.gender)
    );
  },
  username: state => `${state.user.first_name} ${state.user.last_name}`,
  userBalance: state => parseFloat(state.user.balance),
  balanceColor: (state, getters) => {
    //todo update this section
    if (getters.userBalance <= 0)
      //state.user.config.retrait_min)
      return "text-danger";
    else return "text-success";
  },
  userEmail: state => state.user.email,
  country_id: state => state.user.country.id,
  userId: state => state.user.id,
  isCurrentUser: (state, getters) => id => {
    return getters.userId === id;
  },
  isCurrentUserEmail: (state, getters) => email => {
    return getters.userEmail === email;
  },
  isCurrentUserCountry: (state, getters) => id => {
    return getters.country_id === id;
  },
  isCurrentUserMerchant: state => state.user.is_merchant,
  calling_code: state => state.user.country.calling_code,
  phone_length: state => state.user.country.phone_length,
  currency: state => (state.user ? state.user.country.currency_code : "CFA"),
  phoneWithoutCallingCode: state => state.user.phone,
  phone: state => state.user.country.calling_code + " " + state.user.phone,
  userStats: state => state.user.stats ?? {},
  isCivCountry: state => state.user.country.calling_code === "225",
  isBenCountry: state => state.user.country.calling_code === "229",
  isSenCountry: state => state.user.country.calling_code === "221",
  haveMFAEnabled: state => state.user.is_2fa_enabled,
  notifications: state =>
    state.metaNotification ? state.metaNotification.data : [],
  totalNotificationCount: state =>
    state.metaNotification ? state.metaNotification.meta.total : 0,
  getMovementList: state => limit => {
    let movements = [];
    state.metaMovements.forEach(mm => (movements = [...movements, ...mm.data]));
    if (limit)
      movements = movements.filter((m, index) => {
        return m && index < limit;
      });

    return movements;
  },
  merchantPayments: state =>
    state.metaMerchantPayment
      ? state.metaMerchantPayment.data.map(mp => {
          return { ...mp, app_name: mp.application.label };
        })
      : [],
  current_page: state =>
    state.metaMerchantPayment ? state.metaMerchantPayment.meta.current_page : 1,
  last_page: state =>
    state.metaMerchantPayment ? state.metaMerchantPayment.meta.last_page : 1,
  smsPriceSetting: state =>
    state.settings.find(
      userSetting => userSetting.setting.code === settingCode.smsFee
    ),
  getAppLocale: state => {
    const navL = navigator.language.substring(0, 2);
    return state.user?.locale ?? navL ?? "fr";
  },
};

// privileges
const actions = {
  getCurrentUser({ commit, dispatch }) {
    return Promise.all([
      AuthService.getCurrentUser().then(response =>
        commit("SET_CURRENT_USER", response.data.data)
      ),
      dispatch("getCurrentUserSettings", false),
    ]).then(() => {
      return dispatch("getMovements", { page: 1, force: true });
    });
  },
  getCurrentUserSettings({ commit, state }, reload) {
    if (!reload && state.settings.length) return state.settings;
    else
      return NotificationService.getCurrentUserSettings(state.user.id).then(
        ({ data }) => {
          commit("SET_CURRENT_USER_SETTINGS", data.data);
          return data;
        }
      );
  },
  login({ commit, dispatch }, credentials) {
    return AuthService.login(credentials)
      .then(({ data }) => {
        if (!data.user.is_admin) {
          commit("SET_USER_TOKEN", {
            ...data,
            remember: credentials.remember,
            haveToConfirm2Fa: data.user.is_2fa_enabled,
          });
          const notification = {
            type: "success",
            title: "Auth",
            message: this.state.$t("auth.login_message"),
            is_toast: true,
          };
          dispatch("notification/add", notification, { root: true });

          return data;
        } else
          return Promise.reject({
            msg: this.state.$t("auth.cant_login_admin"),
          });
      })
      .catch(error => Promise.reject(error));
  },
  register({ commit }, credentials) {
    return AuthService.register(credentials).then(({ data }) => {
      commit("SET_USER_TOKEN", { ...data, haveToConfirm2Fa: false });
    });
  },
  resendVerifyEmail({ dispatch }) {
    return AuthService.resendVerifyEmail().then(({ data }) => {
      const notification = {
        type: "info",
        message: data.message,
        is_toast: true,
      };
      dispatch("notification/add", notification, { root: true });
    });
  },
  confirmVerifyEmail({ commit, dispatch }, path) {
    return AuthService.getRequest(path)
      .then(({ data }) => {
        commit("VERIFY_USER_EMAIL");
        const notification = {
          type: "info",
          message: data.message,
          is_toast: true,
        };
        dispatch("notification/add", notification, { root: true });
        return data;
      })
      .catch(err => {
        if (err.response.data.message) {
          const notification = {
            type: "danger",
            message: err.response.data.message,
            is_toast: true,
          };
          dispatch("notification/add", notification, { root: true });
        }
        return Promise.reject(err.response.data);
      });
  },
  sendResetPasswordLink({ dispatch }, { email, recaptcha }) {
    return AuthService.resetPasswordLink({ email, recaptcha }).then(
      ({ data }) => {
        if (data.message) {
          const notification = {
            type: "info",
            message: data.message,
            is_toast: true,
          };
          dispatch("notification/add", notification, { root: true });
        }
        return data.message;
      }
    );
  },
  resetPassword({ dispatch }, payload) {
    return AuthService.changePassword({
      ...payload,
      password_confirmation: payload.password,
    }).then(({ data }) => {
      if (data.message) {
        const notification = {
          type: "info",
          message: data.message,
          is_toast: false,
          timeout: 5000,
        };
        dispatch("notification/add", notification, { root: true });
      }
      return data;
    });
  },
  logout({ commit }, service = true) {
    localStorage.removeItem("current_user_credentials");
    localStorage.removeItem("current_user_settings");
    setTimeout(() => commit("CLEAR_USER_DATA"), 3000);
    if (service)
      return AuthService.logout().then(({ data }) => {
        return data;
      });
  },
  sendPhoneConfirmationCode({ dispatch }, phoneData) {
    return AuthService.sendPhoneConfirmationCode(phoneData).then(({ data }) => {
      if (data.message) {
        const notification = {
          type: "info",
          message: data.message,
          is_toast: true,
        };
        dispatch("notification/add", notification, { root: true });
      }
      return data;
    });
  },
  resendPhoneConfirmationCode({ dispatch }, otpId) {
    return AuthService.resendPhoneOtp(otpId).then(({ data }) => {
      if (data.message) {
        const notification = {
          type: "info",
          message: data.message,
          is_toast: true,
        };
        dispatch("notification/add", notification, { root: true });
      }
      return data;
    });
  },
  confirmPhoneNumber({ commit, dispatch }, payload) {
    return AuthService.confirmPhoneNumber(payload).then(({ data }) => {
      commit("SET_USER_PHONE", data.phone);
      if (data.message) {
        const notification = {
          type: "info",
          message: data.message,
          is_toast: true,
        };
        dispatch("notification/add", notification, { root: true });
      }
    });
  },

  updateUser({ commit, dispatch }, user) {
    return AuthService.updateUser(user)
      .then(response => {
        const data = response.data;
        commit("SET_USER", user);
        if (data.message) {
          const notification = {
            type: "info",
            message: data.message,
            is_toast: true,
          };
          dispatch("notification/add", notification, { root: true });
        }
        return dispatch("getCurrentUser");
      })
      .catch(err => {
        if (err.response.data.message) {
          const notification = {
            type: "danger",
            message: err.response.data.message,
            is_toast: true,
          };
          dispatch("notification/add", notification, { root: true });
        }
        return Promise.reject(err.response.data);
      });
  },
  changeLocale({ commit, dispatch }, locale) {
    return AuthService.updateUserLocale({ locale })
      .then(({ data }) => {
        commit("SET_CURRENT_USER_LOCALE", locale);
        if (data.message) {
          const notification = {
            type: "info",
            message: data.message,
            is_toast: true,
          };
          dispatch("notification/add", notification, { root: true });
        }
        return data;
      })
      .catch(err => {
        if (err.response.data.message) {
          const notification = {
            type: "danger",
            message: err.response.data.message,
            is_toast: true,
          };
          dispatch("notification/add", notification, { root: true });
        }
        return Promise.reject(err.response.data);
      });
  },
  updateNotificationSetting({ commit, dispatch }, userSetting) {
    if (userSetting.scoped) {
      return NotificationService.updateUserSetting(
        userSetting.id,
        userSetting.value
      ).then(({ data }) => {
        commit("UPDATE_SETTING", data.data);
        if (data.message) {
          const notification = {
            type: "info",
            message: data.message,
            is_toast: true,
          };
          dispatch("notification/add", notification, { root: true });
        }
        return data;
      });
    } else {
      return NotificationService.createUserSetting({
        setting_id: userSetting.setting.id,
        user_id: state.user.id,
        value: userSetting.value,
      }).then(({ data }) => {
        commit("UPDATE_SETTING", data.data);
        if (data.message) {
          const notification = {
            type: "info",
            message: data.message,
            is_toast: true,
          };
          dispatch("notification/add", notification, { root: true });
        }
        return data;
      });
    }
  },
  changeUserPassword({ dispatch }, payload) {
    return AuthService.updatePassword(payload).then(({ data }) => {
      if (data.message) {
        const notification = {
          type: "success",
          message: data.message,
          is_toast: true,
        };
        dispatch("notification/add", notification, { root: true });
      }
      return data;
    });
  },

  getMovements({ state, commit, dispatch }, { page, force }) {
    const existMeta = state.metaMovements.find(
      mm => mm.meta.current_page === page
    );
    if (existMeta !== undefined && !force) return existMeta;
    else
      return AuthService.getMovements(page)
        .then(({ data }) => {
          if (!force) commit("ADD_META_MOVEMENTS", data);
          return data;
        })
        .catch(err => {
          if (err.response.data.message) {
            const notification = {
              type: "danger",
              message: err.response.data.message,
              is_toast: false,
            };
            dispatch("notification/add", notification, { root: true });
          }
          return Promise.reject(err.response.data);
        });
  },
  getMerchantPaymentList({ commit, state, getters }, { page, force }) {
    if (state.metaMerchantPayment && page === getters.current_page && !force)
      return state.metaMerchantPayment;
    else {
      return AuthService.getPaymentList(page).then(({ data }) => {
        commit("SET_META_MERCHANT_PAYMENT", data);
      });
    }
  },
  getNotifications({ commit, state }, page) {
    if (state.metaNotification && page === state.metaNotification.current_page)
      return state.metaNotification;
    else {
      return AuthService.getNotifications(page).then(({ data }) => {
        commit("SET_META_NOTIFICATION", data);
      });
    }
  },

  chatbotAuthorization({ commit, state }, signature) {
      return AuthService.chatbotAuthorization(signature).then(({ data }) => {
        return data;
      });
  },

  markNotificationAsRead({ state, getters, commit }) {
    if (getters.notifications.length > 0) {
      commit("MARK_NOTIFICATION_READ");
      commit("SET_CURRENT_USER", state.user);
    }
  },

  receiveBroadcastNotification({ state, dispatch }, notify) {
    if (notify.type === "broadcast.transaction") {
      if (notify.message) {
        const notification = {
          type: "info",
          message: notify.message,
          is_toast: true,
        };
        dispatch("notification/add", notification, { root: true });
      }
      switch (notify.operation) {
        case emptyListModel.deposit:
          dispatch(
            "deposit/getDepositList",
            {
              page: 1,
              cancel: true,
              field: { users_id: state.user.id, paginate: 10, search: false },
            },
            { root: true }
          );
          dispatch("getCurrentUser");
          break;
        case emptyListModel.withdraw:
          dispatch(
            "withdraw/getWithdrawList",
            {
              page: 1,
              cancel: true,
              field: { users_id: state.user.id, paginate: 10, search: false },
            },
            { root: true }
          );
          break;
        case emptyListModel.transfer:
          dispatch(
            "transfer/getTransferList",
            {
              page: 1,
              cancel: true,
              field: { users_id: state.user.id, paginate: 10, search: false },
            },
            { root: true }
          );
          break;
        case emptyListModel.shopping:
          dispatch(
            "shopping/getShoppingList",
            {
              page: 1,
              cancel: true,
              field: {
                users_id: state.user.id,
                paginate: 10,
                search: false,
                type: null,
              },
            },
            { root: true }
          );
          break;
      }
    }
  },

  initMfaActivation({ dispatch }, field) {
    return AuthService.initUserMFA(field).then(({ data }) => {
      if (data.message) {
        const notification = {
          type: "info",
          message: data.message,
          is_toast: false,
          timeout: 5000,
        };
        dispatch("notification/add", notification, { root: true });
      }
      return data;
    });
  },
  confirmMfaActivation({ dispatch, state, commit }, field) {
    return AuthService.confirmUserMFA(field).then(({ data }) => {
      if (data.message) {
        const notification = {
          type: "info",
          message: data.message,
          is_toast: false,
          timeout: 5000,
        };
        dispatch("notification/add", notification, { root: true });
      }

      commit("SET_CURRENT_USER", { ...state.user, is_2fa_enabled: true });

      return data;
    });
  },
  regenerateRecoveryCodes({ dispatch }) {
    return AuthService.regenerateUserMFACodes().then(({ data }) => {
      if (data.message) {
        const notification = {
          type: "info",
          message: data.message,
          is_toast: false,
          timeout: 5000,
        };
        dispatch("notification/add", notification, { root: true });
      }
      return data;
    });
  },
  disableMFA({ dispatch, commit, state }) {
    return AuthService.disableUserMFA().then(({ data }) => {
      commit("SET_CURRENT_USER", { ...state.user, is_2fa_enabled: false });
      if (data.message) {
        const notification = {
          type: "info",
          message: data.message,
          is_toast: false,
          timeout: 5000,
        };
        dispatch("notification/add", notification, { root: true });
      }
      return data;
    });
  },
  verifyMFACode({ commit }, field) {
    return AuthService.verifyUser2FACode(field).then(({ data }) => {
      commit("CONFIRM_USER_2FA_VERIFICATION");
      return data;
    });
  },
};

// mutations
const mutations = {
  CONFIRM_USER_2FA_VERIFICATION(state) {
    state.haveToConfirm2Fa = false;
    const userData = getUserSession("current_user_credentials");
    setUserSession("current_user_credentials", {
      ...userData,
      haveToConfirm2Fa: false,
    });
  },
  SET_USER_TOKEN(state, userData) {
    state.user = userData.user;
    state.haveToConfirm2Fa = userData.haveToConfirm2Fa;
    setUserSession("current_user_credentials", userData);
    axios.defaults.headers.common["Authorization"] = `Bearer ${userData.token}`;
  },
  SET_CURRENT_USER(state, user) {
    let userData = getUserSession("current_user_credentials");
    userData.user = user;
    state.user = user;
    setUserSession("current_user_credentials", userData);
  },
  SET_CURRENT_USER_SETTINGS(state, settings) {
    state.settings = settings;
    setUserSession("current_user_settings", settings);
  },
  SET_USER(state, user) {
    state.user = user;
    editCurrentUser(state.user);
  },
  SET_CURRENT_USER_LOCALE(state, locale) {
    state.user.locale = locale;
    editCurrentUser(state.user);
  },
  UPDATE_USER_BALANCE(state, balance) {
    state.user.balance = balance;
    editCurrentUser(state.user);
  },

  TEMP_ADD_TO_BALANCE(state, amount) {
    state.user.balance += amount;
  },

  TEMP_REMOVE_TO_BALANCE(state, amount) {
    state.user.balance -= amount;
  },

  CLEAR_USER_DATA(state) {
    state.user = null;
    state.settings = [];
  },

  VERIFY_USER_EMAIL(state) {
    if (state.user) {
      state.user.email_verified_at = new Date().toDateString();
      editCurrentUser(state.user);
    }
  },

  SET_USER_PHONE(state, phone) {
    state.user.phone = phone;
    state.user.phone_verified_at = new Date();
    editCurrentUser(state.user);
  },

  ADD_META_MOVEMENTS(state, metaMovement) {
    state.metaMovements.push(metaMovement);
  },
  SET_META_NOTIFICATION(state, metaNotification) {
    state.metaNotification = metaNotification;
  },
  SET_META_MERCHANT_PAYMENT(state, metaMerchantPayment) {
    state.metaMerchantPayment = metaMerchantPayment;
  },

  UPDATE_SETTING(state, userSetting) {
    state.settings = state.settings.map(us => {
      return us.setting.id === userSetting.setting.id ? userSetting : us;
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
