import TransferService from "@/services/TransferService";
import { returnBoolValue } from "@/helpers/helpers";

const state = {
  metaTransfer: null,
  settings: [],
};

const getters = {
  transfers: state => (state.metaTransfer ? state.metaTransfer.data : []),
  current_page: state =>
    state.metaTransfer ? state.metaTransfer.meta.current_page : 1,
  last_page: state =>
    state.metaTransfer ? state.metaTransfer.meta.last_page : 1,
  userConfig: state =>
    Object.fromEntries(state.settings.map(obj => [obj.code, obj.value])),
  isTransferAvailable: (state, getters) =>
    returnBoolValue(getters.userConfig.can_transfer),
  canConfirmTransfer: (state, getters, rootStates, rootGetters) => {
    if (returnBoolValue(getters.userConfig.need_valid_profile))
      return rootGetters["auth/isCompleteProfile"];
    else return true;
  },
};

const actions = {
  getTransferList({ commit, state, getters }, { page, keyword }) {
    if (
      !keyword &&
      state.metaTransfer &&
      state.metaTransfer.data.length &&
      page === getters.current_page
    )
      return state.metaTransfer;
    else {
      return TransferService.getTransferList(page, keyword).then(({ data }) => {
        commit("SET_META_TRANSFER", data);
      });
    }
  },
  getTransferSettings({ commit, state }) {
    if (state.settings.length) return state.settings;
    else
      return TransferService.getTransferSettings().then(({ data }) => {
        commit("SET_SETTINGS", data.data);
        return data;
      });
  },
  searchReceiverByEmail({ dispatch }, email) {
    return TransferService.findReceiver(email)
      .then(({ data }) => data.data)
      .catch(err => {
        if (err.response.data.message) {
          const notification = {
            type: "danger",
            message: err.response.data.message,
            is_toast: true,
          };
          dispatch("notification/add", notification, { root: true });
        }
        return Promise.reject(err.response.data);
      });
  },
  confirmTransfer({ commit, dispatch, getters }, transferField) {
    if (getters.canConfirmTransfer) {
      return TransferService.confirmTransfer(transferField)
        .then(({ data }) => {
          commit("ADD_TRANSFER", data.data);
          commit("auth/TEMP_REMOVE_TO_BALANCE", transferField.amount, {
            root: true,
          });

          return data;
        })
        .catch(err => {
          console.log(err);
          if (err.response?.data?.message) {
            let notification = {
              type: "danger",
              message: err.response.data.message,
              is_toast: true,
            };
            dispatch("notification/add", notification, { root: true });
          }
          return Promise.reject(err.response.data);
        });
    } else
      return Promise.reject({
        invalid: true,
      });
  },
};

const mutations = {
  SET_META_TRANSFER(state, metaTransfer) {
    state.metaTransfer = metaTransfer;
  },
  SET_SETTINGS(state, settings) {
    state.settings = settings;
  },
  ADD_TRANSFER(state, transfer) {
    if (state.metaTransfer && state.metaTransfer.data)
      state.metaTransfer.data.unshift(transfer);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
