import axios from "axios";

export default {
  getCurrentUserSettings(user_id) {
    return axios.get(
      `/v1/notification-settings?scope=user&scope_id=${user_id}`
    );
  },

  createUserSetting(userSetting) {
    return axios.put("/v1/notification-settings/users", userSetting);
  },

  updateUserSetting(id, value) {
    return axios.put(`/v1/notification-settings/users/${id}`, {
      value,
    });
  },
};
