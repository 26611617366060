<template>
  <div class="chat-test">
    <TicketDetails />
    <section
      class="header-user-list"
      :class="{
        open: ticketsState,
      }"
    >
      <div class="h-list-header">
        <header-with-divider
          :text="$t('assistance.assistance_and_reclamations')"
          :color="'blue'"
        />
        <div>
          <a href="#" @click="closeAssistance()"><i class="icon-close"></i></a>
        </div>
      </div>
      <div class="h-list-body" id="ticket_list_body">
        <div class="main-friend-cont scroll-div" style="height: 100%;">
          <div class="main-friend-list">
            <template v-if="tickets.length > 0">
              <TicketItem
                v-for="ticket in tickets"
                :key="ticket.id"
                :ticket="ticket"
              />
            </template>
            <BaseEmptyList :model="listModel" v-else />
            <div class="loader-container" v-if="loading">
              <Loading />
            </div>
          </div>
        </div>
      </div>
      <div class="h-list-footer">
        <!-- <BasePagination
          :current_page="current_page"
          :last_page="last_page"
          :isSmall="true"
          @page="getTicketList($event, false)"
        /> -->
        <div class="text-center">
          <a
            href="#"
            @click.prevent="openTicketForm"
            class="new-chat-btn be-btn primary green"
          >
            <i class="icon-plus"></i>
            {{ $t("assistance.new_reclamation") }}
          </a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { assistanceMixin } from "../../mixins/assistanceMixiin";
import { mapGetters, mapState, mapActions } from "vuex";
import TicketItem from "./TicketItem";
import { emptyListModel } from "../../helpers/constants";
import TicketDetails from "./TicketDetails";
import HeaderWithDivider from "../common/HeaderWithDivider";
import Loading from "../common/Loading";

export default {
  name: "TicketList",
  mixins: [assistanceMixin],
  components: { TicketDetails, TicketItem, HeaderWithDivider, Loading },

  data() {
    return {
      timeout: null,
      firstPageInView: 1,
      loading: false,
      field: {
        paginate: 10,
        is_archive: false,
        is_active: true,
        users_id: null,
        order_by: "updated_at",
        direction: "DESC",
      },
    };
  },
  created() {
    this.field.users_id = this.userId;
    // this.getTicketList({
    //   page: 1,
    //   field: this.field,
    // });
  },
  computed: {
    ...mapState(["ticketsState"]),
    ...mapGetters("auth", ["country_id", "userId"]),
    ...mapGetters("assistance", ["tickets", "current_page", "last_page"]),
    listModel() {
      return emptyListModel.assistance;
    },
  },
  mounted() {
    // this.overlayEl = document.querySelector('.blur-overlayer');
    // this.overlayEl.addEventListener('click', this.closeAssistance);
    this.ticketListEl = document.querySelector("#ticket_list_body");
    this.ticketListEl.addEventListener("scroll", this.scrollTicketList, false);
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
    // this.overlayEl.removeEventListener("click", this.closeAssistance);
    this.ticketListEl.removeEventListener(
      "scroll",
      this.scrollTicketList,
      false
    );
  },

  methods: {
    ...mapActions("assistance", ["getTicketList"]),
    ...mapActions(["setTicketsState"]),
    openTicketForm() {
      if (this.$route.name !== "assistance")
        this.$router.push({ name: "assistance" });
      this.closeAssistance();
    },
    scrollTicketList(e) {
      if (!this.loading && this.current_page < this.last_page) {
        const { scrollTop, offsetHeight, scrollHeight } = e.target;
        if (scrollHeight - scrollTop - offsetHeight < 80) {
          this.loading = true;
          this.$nextTick(() => {
            var objDiv = document.getElementById("ticket_list_body");
            objDiv.scrollTo({ top: scrollHeight, behavior: "smooth" });
          });
          if (this.tm) {
            clearTimeout(this.tm);
          }
          this.tm = setTimeout(() => {
            this.loading &&
              this.getTicketList({
                page: ++this.firstPageInView,
                field: this.field,
              }).then(
                () => (this.loading = false),
                () => (this.loading = false)
              );
          }, 500);
        }
      }
    },
    closeAssistance() {
      this.setTicketsState(false);
      window.$(".header-chat").removeClass("open");
      document.body.style.overflow = "auto";
    },
  },
};
</script>

<style scoped></style>
