import Dashboard from "../views/Dashboard";
import profile from "./profile";
import deposit from "./deposit";
import withdraw from "./withdraw";
import transfer from "./transfer";
import shopping from "./shopping";
import { accessTicketForm } from "@/middleware/authMiddleware";
import payment from "./payment";

const account = [
  {
    path: "/",
    redirect: { name: "dashboard" },
  },
  {
    path: "dashboard",
    name: "dashboard",
    component: Dashboard,
  },
  {
    path: "profile",
    component: () => import("../views/profile/Profile"),
    children: profile,
  },
  {
    path: "/recent-transactions",
    component: () => import("../views/profile/MovementList.vue"),
    name: "movement",
  },
  {
    path: "/merchant-payments",
    component: () => import("../views/profile/MerchantPayment.vue"),
    name: "merchantPayment",
  },
  {
    path: "/notifications",
    component: () => import("../views/profile/Notification"),
    name: "notification",
  },
  {
    path: "deposit",
    component: () => import("../views/deposit/DepositPage"),
    children: deposit,
  },
  {
    path: "withdraw",
    component: () => import("../views/withdraw/WithdrawPage"),
    children: withdraw,
  },
  {
    path: "transfer",
    component: () => import("../views/transfer/TransferPage"),
    children: transfer,
  },
  {
    path: "shopping",
    component: () => import("../views/shopping/ShoppingPage"),
    children: shopping,
  },
  {
    path: "payment",
    component: () => import("../views/payment/PaymentPage"),
    children: payment,
  },
  {
    path: "/help-center",
    props: true,
    component: () => import("../views/assistance/AssistanceForm"),
    name: "assistance",
    beforeEnter: accessTicketForm,
  },
];

export default account;
