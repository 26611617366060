import store from "@/store";

const withdraw = [
  {
    path: "/",
    redirect: { name: "withdraw" },
  },
  {
    path: "list",
    name: "withdraw",
    component: () => import("../views/withdraw/WithdrawList"),
    children: [
      {
        path: ":id",
        name: "withdraw.detail",
        component: () => import("../views/withdraw/WithdrawDetails"),
        props: true,
        beforeEnter(routeTo, routeFrom, next) {
          store
            .dispatch("withdraw/getWithdraw", routeTo.params.id)
            .then(withdraw => {
              routeTo.params.withdraw = withdraw;
              next();
            })
            .catch(() =>
              next({
                name: "withdraw",
              })
            );
        },
      },
    ],
  },
  {
    path: "initiate",
    name: "withdraw.form",
    component: () => import("../views/withdraw/WithdrawForm"),
    beforeEnter(routeTo, routeFrom, next) {
      store.dispatch("withdraw/getUserSettings").then(() => {
        if (store.getters["withdraw/canProcessWithdraw"])
          Promise.all([
            store.dispatch("withdraw/getWithdrawOperators"),
            store.dispatch("withdraw/getContacts"),
          ]).then(() => {
            next();
          });
        else next();
      });
    },
  },
];

export default withdraw;
