import axios from "axios";

export default {
  getDepositList(page, keyword) {
    return axios.get(
      `/v1/deposits?page=${page}&paginate=10${
        keyword ? "&filter[search]=" + keyword : ""
      }`
    );
  },
  getDeposit(id) {
    return axios.get(`/v1/deposits/${id}`);
  },
  getDepositSettings(scoped = "", scope_id = "") {
    return axios.get(
      `/v1/deposit-settings${
        scoped && scope_id ? `?scope=${scoped}&scope_id=${scope_id}` : ""
      }`
    );
  },

  initiateDeposit(payload) {
    return axios.post("/v1/deposits", payload);
  },
  verifyDeposit(id) {
    return axios.get(`/v1/deposits/${id}/repointing`);
  },
};
