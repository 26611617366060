<template>
  <header class="header-menu w-100">
    <div class="web-header">
      <!-- <button
        data-v-22ba47ca=""
        class="be-btn secondary lg green acheter-un-service"
      >
        Acheter Un Service
      </button> -->
      <!-- <ShoppingSelect /> -->
      <div
        class="logo d-flex align-items-center justify-content-center"
        :class="{
          'minW-320': isSidebarOpened,
          'minW-100': !isSidebarOpened,
        }"
      >
        <a href="" style="display: block;" v-if="isSidebarOpened">
          <img alt="" src="../../assets/images/logo/logo.svg" />
        </a>
        <a href="" style="display: block;" v-else>
          <img alt="" src="../../assets/images/logo/logo-collapsed.svg" />
        </a>
      </div>
      <div class="d-flex align-items-center justify-content-between w-100">
        <div class="width-223">
          <be-multiselect
            v-model="selected"
            :options="remappedServices"
            track-by="id"
            label="serviceName"
            :close-on-select="true"
            withImages
            @input="openShoppingForm"
            dashboard
          />
        </div>
        <div class="header-menu-right-side mr-4">
          <div class="label-rounded white f-w-600">
            <i class="icon-card mr-2 f-24" />
            {{ userBalance | numFormat }} {{ currency }}
          </div>
          <span class="vertical-divider" />

          <BeSelect
            icon="icon-bell"
            :label="totalNotificationCount.toString()"
            :options="[]"
            :name="'my_notifications'"
          />
          <div class="rounded-icon" @click="openTicketsList">
            <i class="icon-support" v-if="!loadingHelp"></i>
          </div>
          <span class="vertical-divider" />
          <BeSelect
            icon="icon-user-fill"
            :label="username"
            :options="profileOptions"
            :name="'my_profile'"
          />
          <!-- <b class="ml-3 f-w-300 f-18" :class="balanceColor"> lorem </b> -->
        </div>
      </div>
    </div>
    <div class="mobile-header mobile-headerSticky">
      <div class="toggle-menu rounded-icon" @click="$emit('toggle-sidebar')">
        <span class="icon-menu" v-if="!isSidebarOpened"
          ><span class="path1"></span><span class="path2"></span
          ><span class="path3"></span><span class="path4"></span
        ></span>
        <i class="icon-close" v-else />
      </div>
      <div class="logo">
        <a @click="$router.push({ name: 'dashboard' })" style="display: block;">
          <img alt="" src="../../assets/images/logo/logo.svg" />
        </a>
      </div>
      <div>
        <div class="rounded-icon" @click="openTicketsList">
          <i class="icon-support" v-if="!loadingHelp"></i>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
// import ShoppingSelect from '../shopping/ShoppingSelect';
// import NotificationHeader from "../profile/NotificationHeader";
import BeSelect from "../common/BeSelect";
import { assistanceMixin } from "@/mixins/assistanceMixiin";
import BeMultiselect from "../common/BeMultiselect.vue";
import { getServiceName } from "@/helpers/utils";

export default {
  name: "Header",
  props: {
    isSidebarOpened: {
      type: Boolean,
      default: true,
    },
  },
  components: { /* ShoppingSelect, */ BeSelect, BeMultiselect },
  mixins: [assistanceMixin],
  data() {
    return {
      selected: null,
    };
  },
  watch: {
    // activeServices(value) {
    //   if (value.length > 0 && this.index)
    //     this.selected = this.activeServices.find(
    //       s => s.index.toLowerCase() === this.index
    //     );
    // },
    index(value) {
      if (value === null) this.selected = null;
    },
  },
  computed: {
    ...mapGetters("auth", [
      "balanceColor",
      "currency",
      "totalNotificationCount",
      "userBalance",
      "username",
    ]),
    ...mapState("shopping", ["serviceLoading"]),
    ...mapGetters("shopping", ["activeServices"]),
    ...mapState(["routerLoading", "ticketsState"]),
    index() {
      return this.$route.params.index ? this.$route.params.index : null;
    },
    loadingSelect() {
      return this.serviceLoading || this.routerLoading;
    },
    profileOptions() {
      return [
        {
          label: this.$t("base.navMenu.profile"),
          icon: "icon-user",
          route: "profile",
        },
        {
          label: this.$t("base.navMenu.merchant_payment"),
          icon: "icon-shop",
          route: "merchantPayment",
        },
        {
          label: this.$t("base.navMenu.movements"),
          icon: "icon-movements",
          route: "movement",
        },
        {
          label: this.$t("base.navMenu.settings"),
          icon: "icon-settings",
          route: "formSetting",
        },
        {
          label: this.$t("base.navMenu.logout"),
          icon: "icon-log-out",
          fn: this.logout,
        },
      ];
    },
    remappedServices() {
      return this.activeServices.map(s => ({
        ...s,
        serviceName: getServiceName(s),
      }));
    },
  },
  methods: {
    ...mapActions(["setTicketsState"]),
    logout() {
      this.$store.dispatch("auth/logout").then(() => {
        location.assign("/login");
      });
    },
    openShoppingForm() {
      if (this.selected)
        this.$router.push({
          name: "shopping.form",
          params: {
            type_code: this.selected.type_service.code.toLowerCase(),
            index: this.selected.index.toLowerCase(),
          },
        });
    },
    openTicketsList() {
      this.setTicketsState(!this.ticketsState);
      document.body.style.overflow = "hidden";
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-headerSticky {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background: #0b1527;
  z-index: 100;
}
</style>
