import WithdrawService from "@/services/WithdrawService";
import OperatorService from "@/services/OperatorService";
import { settingScopeCode } from "@/helpers/constants";
import { returnBoolValue } from "@/helpers/helpers";

const state = {
  metaWithdraw: null,
  withdraw_operators: [],
  contacts: [],
  user_settings: [],
};

const getters = {
  withdraws: state => (state.metaWithdraw ? state.metaWithdraw.data : []),
  current_page: state =>
    state.metaWithdraw ? state.metaWithdraw.meta.current_page : 1,
  last_page: state =>
    state.metaWithdraw ? state.metaWithdraw.meta.last_page : 1,
  operators: state =>
    state.withdraw_operators.map(dop => {
      return {
        id: dop.id,
        code: dop.code,
        logo_url: dop.logo_url,
        label: dop.label,
      };
    }),
  getOperatorById: state => id => {
    const operator = state.withdraw_operators.find(op => op.id === id);
    if (operator !== undefined)
      return {
        ...operator,
        setting: Object.fromEntries(
          operator.settings.map(obj => [obj.code, obj.value])
        ),
      };
    else return {};
  },
  userConfig: state =>
    Object.fromEntries(state.user_settings.map(obj => [obj.code, obj.value])),
  canProcessWithdraw: (state, getters) =>
    returnBoolValue(getters.userConfig?.can_withdraw ?? false),
  isWithdrawAvailable: (state, getters) =>
    state.withdraw_operators.length > 0 && getters.canProcessWithdraw,
  getContactById: state => id => {
    return state.contacts.find(c => c.id === id);
  },
};

const actions = {
  getWithdrawList({ commit, state, getters }, { page, keyword }) {
    if (!keyword && state.metaWithdraw && page === getters.current_page)
      return state.metaWithdraw;
    else {
      return WithdrawService.getWithdrawList(page, keyword).then(({ data }) => {
        commit("SET_META_WITHDRAW", data);
        return data;
      });
    }
  },
  getWithdraw({ getters, dispatch }, id) {
    const withdraw = getters.withdraws.find(w => w.id === id);
    if (withdraw !== undefined) return withdraw;
    else
      return WithdrawService.getWithdraw(id)
        .then(({ data }) => {
          return data.data;
        })
        .catch(err => {
          if (err.response.data.message) {
            const notification = {
              type: "danger",
              message: err.response.data.message,
              is_toast: true,
            };
            dispatch("notification/add", notification, { root: true });
          }
          return Promise.reject(err.response.data);
        });
  },
  getWithdrawOperators({ commit, state }) {
    if (state.withdraw_operators.length > 0) return state.withdraw_operators;
    else
      return OperatorService.getWithdrawOperators().then(({ data }) => {
        commit("SET_WITHDRAW_OPERATORS", data.data);
      });
  },
  getUserSettings({ commit, state, rootGetters }) {
    if (state.user_settings.length) return state.user_settings;
    else
      return WithdrawService.getWithdrawSettings(
        settingScopeCode.user,
        rootGetters["auth/userId"]
      ).then(({ data }) => {
        commit("SET_USER_SETTINGS", data.data);
        return data.data;
      });
  },
  getContacts({ commit, state }) {
    if (state.contacts.length > 0) return state.contacts;
    else
      return WithdrawService.getContacts().then(({ data }) => {
        commit("SET_CONTACTS", data.data);

        return data.data;
      });
  },
  addContact({ commit }, contact) {
    return WithdrawService.addContact(contact).then(({ data }) => {
      commit("ADD_CONTACT", data.data);

      return data.data;
    });
  },
  updateContact({ commit }, contact) {
    return WithdrawService.updateContact(contact).then(({ data }) => {
      commit("UPDATE_CONTACT", data.data);

      return data.data;
    });
  },
  deleteContact({ commit, dispatch }, contact) {
    return WithdrawService.deleteContact(contact.id).then(({ data }) => {
      commit("REMOVE_CONTACT", data.data);
      const notification = {
        type: "info",
        message: data.data.message,
        is_toast: true,
      };
      dispatch("notification/add", notification, { root: true });

      return data.data;
    });
  },
  addServiceNumber({ commit }, serviceNumber) {
    return WithdrawService.addServiceNumber(serviceNumber).then(({ data }) => {
      commit("ADD_SERVICE_NUMBER", data.data);

      return data.data;
    });
  },
  deleteServiceNumber({ commit, dispatch }, serviceNumber) {
    return WithdrawService.deleteServiceNumber(serviceNumber.id).then(
      ({ data }) => {
        commit("DELETE_SERVICE_NUMBER", serviceNumber);
        const notification = {
          type: "info",
          message: data.data.message,
          is_toast: true,
        };
        dispatch("notification/add", notification, { root: true });

        return data.data;
      }
    );
  },

  confirmWithdraw({ commit, dispatch }, withdrawField) {
    return WithdrawService.confirmWithdraw(withdrawField)
      .then(({ data }) => {
        commit("ADD_WITHDRAW", data.withdraw);
        const notification = {
          type: "success",
          message: data.message,
          is_toast: false,
        };
        dispatch("notification/add", notification, { root: true });

        commit(
          "auth/TEMP_REMOVE_TO_BALANCE",
          parseFloat(data.withdraw.amount),
          {
            root: true,
          }
        );
        return data;
      })
      .catch(err => {
        console.log(err);
        if (err.response.data.message) {
          let notification = {
            type: "danger",
            message: err.response.data.message,
            is_toast: true,
          };
          dispatch("notification/add", notification, { root: true });
        }
        if (err.response.data.refresh)
          dispatch("auth/getCurrentUser", null, { root: true });
        return Promise.reject(err.response.data);
      });
  },
};

const mutations = {
  SET_META_WITHDRAW(state, metaWithdraw) {
    state.metaWithdraw = metaWithdraw;
  },
  ADD_WITHDRAW(state, withdraw) {
    if (state.metaWithdraw) state.metaWithdraw.data.unshift(withdraw);
  },
  SET_USER_SETTINGS(state, user_settings) {
    state.user_settings = user_settings;
  },
  SET_WITHDRAW_OPERATORS(state, withdraw_operators) {
    state.withdraw_operators = withdraw_operators;
  },

  SET_CONTACTS(state, contacts) {
    state.contacts = contacts;
  },
  ADD_CONTACT(state, contact) {
    state.contacts.unshift(contact);
  },
  UPDATE_CONTACT(state, contact) {
    state.contacts = state.contacts.map(c => {
      if (c.id === contact.id) return contact;
      else return c;
    });
  },
  REMOVE_CONTACT(state, contact) {
    state.contacts = state.contacts.filter(c => c.id !== contact.id);
  },

  ADD_SERVICE_NUMBER(state, service_number) {
    state.contacts = state.contacts.map(contact => {
      if (contact.id === service_number.contact_id) {
        contact.service_numbers.push(service_number);
      }
      return contact;
    });
  },
  DELETE_SERVICE_NUMBER(state, service_number) {
    state.contacts = state.contacts.map(contact => {
      if (contact.id === service_number.contact_id) {
        contact.service_numbers = contact.service_numbers.filter(
          info => info.id !== service_number.id
        );
      }
      return contact;
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
