<template>
  <div class="be-popover-bottom">
    <div class="be-popover-header">
      <span>
        <i class="icon-user-fill f-28" />
      </span>
      <div>
        <p>{{ userNameFemale }}</p>
        <p>{{ userEmail }}</p>
      </div>
    </div>
    <hr />
    <div class="be-popover-center">
      <div
        v-for="(option, idx) in profileOptions"
        :key="idx"
        class="single-item"
        :class="{ active: $route.name === option.route }"
      >
        <router-link
          v-if="option.route && option.route !== '#'"
          :to="{
            name: option.route,
          }"
        >
          <i v-if="option.icon" :class="[option.icon, 'f-24']" />
          {{ option.label }}
        </router-link>
        <span v-else-if="option.route === '#'">
          <i v-if="option.icon" :class="[option.icon, 'f-24']" />
          {{ option.label }}
        </span>
      </div>
    </div>
    <hr />
    <div class="be-popover-footer">
      <div class="single-item">
        <a href="#" @click.prevent="footerContent[0].fn">
          <i
            v-if="footerContent[0].icon"
            :class="[footerContent[0].icon, 'f-24']"
          />
          {{ footerContent[0].label }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "MyProfilePopover",

  data() {
    return {};
  },
  props: {
    profileOptions: {
      type: Array,
    },
  },
  computed: {
    ...mapState("auth", ["user"]),
    userNameFemale() {
      return `${this.user.first_name} ${this.user.last_name}`;
    },
    userEmail() {
      return `${this.user.email}`;
    },
    footerContent() {
      return this.profileOptions.slice(this.profileOptions.length - 1);
    },
  },
};
</script>

<style lang="scss" scoped></style>
