export default {
  recharge_number_has_been_updated:
    "Votre numéro de recharge {label} a été mis à jour !",
  deposit_amount_must_be_multiple_of:
    "Le montant du dépôt doit être un multiple de",
  deposit_amount_must_be_greater_than:
    "Le montant du dépôt doit être supérieur ou égale à",
  deposit_amount_must_be_lower_than:
    "Le montant du dépôt doit être inférieur ou égale à",
  deposit_tax_message:
    "Vous allez recevoir une demande de retrait de {amount_without_tax} {currency}." +
    "Votre compte sera crédité d'un montant total de {amount} {currency}",
  deposit_tax_support_message:
    "Vous devez avoir {amount} {currency} + {tax_value} {currency} (frais " +
    "de retrait {operator}) dans votre compte. Votre compte sera" +
    " crédité d'un montant total de {amount_with_tax} {currency}",
  recharge_of_var: "Recharge de ${amount} BTC ($1 BTC = {operator} {currency})",
  attention_value_can_change:
    "Attention les valeurs peuvent changer durant le paiement.",
  dial_the_code: "Sur votre mobile, Composez le",
  dial_the_code_for: "pour obtenir le code paiement",
  the_var_value_is_not_valid: "La valeur {label} n'est pas valide",
  the_var_value_is_length_not_valid:
    "La valeur du {label} doit être {length} caractères",
  please_input_the_var_value: "Veuillez saisir le champs {label}",
  start_a_deposit: "Démarrer le dépôt",
  the_deposit_amount: "le montant du dépôt",
  deposit_amount: "Montant du dépôt",
  insert_the_amount: "Entrez un montant",
  choose_deposit_amount: "Choisir le montant du dépôt",
  do_: "Effectuer",
  a_deposit: "Un dépôt",
  of_deposit: "du dépôt",
  deposit_details: "Détails dépôt",
  deposit_id: "Identifiant du dépôt",
  new_recharge: "Nouvelle recharge",
  choose_number: "--Choisir le numéro--",
  deposit_number: "Entrez le numéro qui sera utilisé pour vos dépôts ",
  scan_code: "Scanner le code",
  deposit_of: "Dépôt de",
  no_specified: "Non spécifié",
  please_compose_before: "Veuillez composez le",
  please_compose_after: "pour confirmer l'opération.",
  recharge_list: "Liste des recharges",
  start_with_a_recharge: "Commencer par recharger votre compte",
  success_recharge_of_bewallet:
    "Votre recharge a été effectué avec succès. Si votre compte n'a pas encore été crédité, patientez encore un instant.",
  recharge_of_bewallet_is_canceled:
    "La recharge de votre compte BeWallet a été annulé ! S'il s'agit d'une erreur, veuillez initier un ticket.",
  cant_change_number_desc_after:
    "numéros sont autorisés !!! Vous ne pouvez pas changer un numéro déjà enregistré.",
  number_is_confirm: "Votre numéro de recharge a été mis à jour !",
};
