export default {
  navMenu: {
    profile: "Mon profil",
    merchant_payment: "Paiements Marchant",
    settings: "Parametres",
    movements: "Mouvements",
    documentation: "Documentation API",
    logout: "Deconnexion",
  },
  options: "options",
  selected_options: "options selectionnees",
  all_notifications: "Voir toutes les notifications",
  notifications: "Notifications",
  buy_a_service_with_: "--Acheter un service--",
  buy_a_service: "Acheter un service",
  choose_service: "Choisir un service",
  messages: {
    disableDeposit: "Les dépôts ne sont pas disponibles pour le moment !",
    disableWithdraw: "Les retraits ne sont pas disponibles pour le moment !",
    disableTransfer: "Les transferts ne sont pas disponibles pour le moment !",
    is_available_at_moment: "est disponible pour le moment",
    assistance: "Vous n'avez aucun ticket en cours.",
    payment_with_key:
      "Nous n'avons trouvé aucun paiement contenant le mot clé.",
    payment_not_received: "Aucun paiement n'a été réçu pour le moment.",
    message: "Vous n'avez pas de messages",
    movement: "Vous n'avez effectué aucune opération pour le moment.",
    deposit_with_key: "Nous n'avons trouvé aucun dépôt contenant le mot clé",
    recharge: "Commencer par recharger votre compte.",
    withdraw_with_key:
      "Nous n'avons trouvé aucun retrait contenant le mot clé ",
    withdraw_none: "Vous n'avez effectué aucun retrait d'argent.",
    transfer_with_key:
      "Nous n'avons trouvé aucun transfert contenant le mot clé",
    transfer_none: "Vous n'avez effectué aucun transfert vers un autre compte.",
    shopping_with_key:
      "Nous n'avons trouvé aucun achat de service contenant le mot clé ",
    shopping_no_order:
      "Vous n'avez acheté aucun service BeWallet pour le moment.",
  },
  just_operator: "Seul l'opérateur",
  choose_operator: "-- Choisir un opérateur --",
  create_new_ticket_on_operator: "Créer un ticket sur l'operation",
};
