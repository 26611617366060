import axios from "axios";

export default {
  login(credentials) {
    return axios.post("/v1/auth/login", credentials);
  },

  logout() {
    return axios.get("/v1/auth/logout");
  },

  register(credentials) {
    return axios.post("/v1/auth/register", credentials);
  },

  resendVerifyEmail() {
    return axios.post("/v1/auth/email/verification-notification");
  },

  getRequest(url) {
    return axios.get(url);
  },

  resetPasswordLink(payload) {
    return axios.post("/v1/auth/forgot-password", payload);
  },

  changePassword(payload) {
    return axios.post("/v1/auth/reset-password", payload);
  },

  sendPhoneConfirmationCode(phoneData) {
    return axios.post("/v1/auth/phone/verification-sms", phoneData);
  },

  resendPhoneOtp(otpId) {
    return axios.get(`/v1/auth/phone/verification-sms/${otpId}`);
  },

  confirmPhoneNumber(confirmData) {
    return axios.post("/v1/auth/phone/verify", confirmData);
  },

  updateUser(user) {
    return axios.put("/v1/auth/profile", user);
  },

  updateUserLocale(localeField) {
    return axios.put("/v1/auth/profile/locale", localeField);
  },

  updatePassword(payload) {
    return axios.post("/v1/auth/profile/change-password", payload);
  },

  getCurrentUser() {
    return axios.get("/v1/auth/profile");
  },

  getMovements(page = 1) {
    return axios.get(`/v1/movements?paginate=10&page=${page}`);
  },

  getNotifications(page = 1) {
    return axios.get(`/v1/notifications?page=${page}`);
  },

  getPaymentList(page) {
    return axios.get(
      `/v1/payments?paginate=10&page=${page}&include=application`
    );
  },
  searchFromPaymentList(page, field) {
    return axios.post("/payments/list/search?page=" + page, field);
  },

  initUserMFA(field) {
    return axios.post("/v1/auth/profile/mfa/init", field);
  },

  confirmUserMFA(field) {
    return axios.post("/v1/auth/profile/mfa/confirm", field);
  },

  regenerateUserMFACodes() {
    return axios.get("/v1/auth/profile/mfa/regenerate");
  },

  disableUserMFA() {
    return axios.delete("/v1/auth/profile/mfa/disable");
  },

  verifyUser2FACode(verifyField) {
    return axios.post("/v1/auth/profile/mfa/verify", verifyField);
  },

  chatbotAuthorization(signature) {
    return axios.get(`/v1/assistances/chatbots/${signature}/access`);
  }
};
